import {
  LensClient,
  PublicationMetadataV2Input,
  development,
  production,
} from "@lens-protocol/client"

const IPFS_API = "https://ipfs-gateway.herokuapp.com"

export const lensClient = new LensClient({
  environment: development,
  storage: localStorage,
})

export async function upload(data: PublicationMetadataV2Input): Promise<string> {
  const response = await fetch(`${IPFS_API}/upload`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((r) => r.json())

  if (response.error) {
    throw new Error(response.error)
  }

  // wait for availability
  // TODO: this is going to make things way slower
  // TODO: find a way to make content available faster
  // TODO: don't ignore all errors thrown by fetch
  let res: any
  do {
    try {
      const controller = new AbortController()
      const id = setTimeout(() => controller.abort(), 20000) // 20 seconds

      res = await fetch(`${response.cid}.ipfs.w3s.link/${response.filename}`, {
        signal: controller.signal,
      })
      clearTimeout(id)
    } catch {}
  } while ((res?.status ?? 504) === 504)

  return `ipfs://${response.cid}/${response.filename}`
}
