import { Image, Typography } from "antd"
import Header from "../components/Header"
import { useParams } from "react-router"
import useProfile from "../state/auth/useProfile"
import { useEffect, useState } from "react"
import Title from "antd/es/typography/Title"
import { ipfsToHttps } from "../utils/ipfsToHttps"

const ProfilePage = () => {
  const { id } = useParams()
  const { profile } = useProfile()

  return (
    <div>
      <Header />
      {profile && profile.id === id ? (
        <div className="px-16 py-8 flex flex-col">
          <div className="flex">
            <Image
              width={250}
              src={ipfsToHttps((profile.picture as any).original.url)}
              fallback="/fallback.png"
              height={256}
            />
            <div className="flex flex-col ml-4 py-4">
              <Title level={2}>
                {profile.handle} {profile.name ? `| ${profile.name}` : ""}
              </Title>
              <Typography>{profile.bio}</Typography>
            </div>
          </div>
        </div>
      ) : (
        <h1>Not Implemented</h1>
      )}
    </div>
  )
}
export default ProfilePage
