import { Button, Dropdown, Image, MenuProps, Tag, Typography } from "antd"
import Title from "antd/es/typography/Title"
import useProfile from "../state/auth/useProfile"
import useEthAddress from "../state/auth/useEthAddress"
import { useEffect } from "react"
import { LogoutOutlined, MehOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router"
import Search from "antd/es/input/Search"

const Header = (props: { createPost?: boolean }) => {
  const { ethAddress, refresh, enabled, enable } = useEthAddress()
  const { profile, error, login, logout } = useProfile()
  const navigate = useNavigate()

  const items: MenuProps["items"] = [
    {
      key: "profile",
      label: "Profile",
      icon: <MehOutlined />,

      onClick: () => navigate(`/profile/${profile?.id}`),
    },
    {
      key: "logout",
      label: "Logout",
      icon: <LogoutOutlined />,
      danger: true,
      onClick: logout,
    },
  ]

  useEffect(() => {
    if (!ethAddress) {
      refresh()
    }
  }, [])

  return (
    <div className="flex border-b-2 p-4 justify-between items-center">
      <div className="flex items-center">
        <a href="/" className="flex items-center">
          <Image height={40} src="/logo512.png" preview={false} />
          <Title level={2} className="ml-2" style={{ marginBottom: 0 }}>
            Fiftyone
          </Title>
          <Tag className="ml-2" color="#E77F6E">
            Testnet Alpha
          </Tag>
        </a>

        <Search
          className="ml-8"
          placeholder="Find discussions or users"
          onSearch={(val) => navigate(`/search?q=${val}`)}
          style={{ width: 250 }}
        />
        {props.createPost && profile?.id && (
          <Button type="link" className="mr-2" onClick={() => navigate("/post/create")}>
            Create Post
          </Button>
        )}
      </div>

      <div className="flex items-center">
        {profile?.id && (
          <div>
            <Dropdown arrow menu={{ items }} className="cursor-pointer">
              <Typography className="mr-2" onClick={() => navigate(`/profile/${profile?.id}`)}>
                {profile?.handle}
              </Typography>
            </Dropdown>
          </div>
        )}
        {ethAddress && !profile?.id && !error && (
          <div>
            <Button onClick={login}>Login</Button>
          </div>
        )}
        {!ethAddress && !profile && !enabled && <Button onClick={enable}>Enable Ethereum</Button>}
        {!ethAddress && !profile && enabled && (
          <Typography className="mr-2">Sign in to your Wallet to login</Typography>
        )}
        {ethAddress && !profile && error && (
          <Typography className="mr-2 text-red-500">{error}</Typography>
        )}
      </div>
    </div>
  )
}
export default Header
