import { useEffect, useState } from "react"
import Header from "../components/Header"
import useQueryParams from "../utils/useQueryParams"
import { CommentFragment, PostFragment, ProfileFragment } from "@lens-protocol/client"
import { fetchSearchResults } from "../api/search"
import PostCard from "../components/PostCard"
import ProfileCard from "../components/ProfileCard"

const SearchPage = () => {
  const [searchResults, setSearchResults] = useState<
    (CommentFragment | PostFragment | ProfileFragment)[]
  >([])
  const { q } = useQueryParams()

  useEffect(() => {
    ;(async () => {
      const results = await fetchSearchResults(q)
      setSearchResults(results)
    })()
  }, [q])

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <Header createPost />

      <div className="flex-1 flex flex-col px-16 py-8 overflow-auto">
        {searchResults.map((result) => (
          <>
            {result.__typename === "Post" && <PostCard post={result} className="mb-4" />}
            {result.__typename === "Profile" && <ProfileCard profile={result} />}
          </>
        ))}
      </div>
    </div>
  )
}
export default SearchPage
