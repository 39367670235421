import { useCallback, useState } from "react"
import { PROVIDER, useAuthState } from "."

const useEthAddress = (): {
  ethAddress: string
  enabled: boolean

  refresh: () => Promise<void>
  enable: () => Promise<void>
} => {
  const [state, dispatch] = useAuthState()

  return {
    ethAddress: state.ethAddress,
    enabled: state.ethEnabled,

    refresh: useCallback(async () => {
      const accounts = await PROVIDER.listAccounts()
      console.log({ accounts })
      if (accounts.length) {
        dispatch({ type: "SET_ETH_ADDRESS", payload: { address: accounts[0] } })
      }
    }, []),

    enable: useCallback(async () => {
      const accounts = await (window as any).ethereum.enable()
      dispatch({ type: "SET_ETH_ENABLED", payload: { enabled: accounts.length > 0 } })
      if (accounts.length > 0) {
        dispatch({ type: "SET_ETH_ADDRESS", payload: { address: accounts[0] } })
      }
    }, []),
  }
}
export default useEthAddress
