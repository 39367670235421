import React, { useEffect } from "react"
import Header from "../components/Header"
import usePosts from "../state/post/usePosts"
import PostCard from "../components/PostCard"
import { Empty, Spin } from "antd"
import useProfile from "../state/auth/useProfile"

const HomePage = () => {
  const { profile } = useProfile()
  const { posts, loading, refresh } = usePosts({ limit: 10 })

  useEffect(() => {
    if (profile?.id) {
      refresh()
    }
  }, [profile?.id])

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <Header createPost />

      <div className="flex-1 flex flex-col items-center overflow-auto">
        <div className="flex-1 flex flex-col max-w-5xl px-16 py-8">
          {!profile?.id && Object.keys(posts).length === 0 && <Empty description="Not logged in" />}
          {loading && <Spin size="large" />}
          {!loading &&
            Object.keys(posts).map((id: string) => (
              <PostCard key={posts[id].id} post={posts[id]} className="mb-8" />
            ))}
        </div>
      </div>
    </div>
  )
}
export default HomePage
