import { PropsWithChildren } from "react"
import AuthProvider from "./auth/index"
import PostProvider from "./post"

const StateProvider = ({ children }: PropsWithChildren) => {
  return (
    <AuthProvider>
      <PostProvider>{children}</PostProvider>
    </AuthProvider>
  )
}
export default StateProvider
