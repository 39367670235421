import { CommentFragment, PostFragment, ProfileFragment } from "@lens-protocol/client"
import { lensClient } from "."

// TODO: handle pagination
// TODO: sort based on relevance
export async function fetchSearchResults(
  query: string
): Promise<(CommentFragment | PostFragment | ProfileFragment)[]> {
  const profilePromise = lensClient.search.profiles({ query, limit: 10 })
  const publicationPromise = lensClient.search.publications({ query, limit: 10 })
  const [profiles, publications] = await Promise.all([profilePromise, publicationPromise])

  return [...profiles.items, ...publications.items]
}
