import { ProfileFragment } from "@lens-protocol/client"

export interface AuthState {
  ethAddress: string
  ethEnabled: boolean
  profile?: ProfileFragment
}

export interface SetEthAddressAction {
  type: "SET_ETH_ADDRESS"
  payload: {
    address: string
  }
}

export interface SetEthEnabledAction {
  type: "SET_ETH_ENABLED"
  payload: {
    enabled: boolean
  }
}

export interface SetProfileAction {
  type: "SET_PROFILE"
  payload: {
    profile: ProfileFragment
  }
}

export interface LogoutAction {
  type: "LOGOUT"
}

export type AuthAction = SetEthAddressAction | SetEthEnabledAction | SetProfileAction | LogoutAction

export default (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case "SET_ETH_ADDRESS": {
      return {
        ...state,
        ethAddress: action.payload.address,
        ethEnabled: !!action.payload.address,
      }
    }
    case "SET_ETH_ENABLED": {
      return {
        ...state,
        ethEnabled: action.payload.enabled,
      }
    }
    case "SET_PROFILE": {
      return {
        ...state,
        profile: action.payload.profile,
      }
    }
    case "LOGOUT": {
      return {
        ...state,
        profile: undefined,
      }
    }
    default: {
      return state
    }
  }
}
