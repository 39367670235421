import { PostFragment } from "@lens-protocol/client"
import { Card } from "antd"
import Meta from "antd/es/card/Meta"
import { useNavigate } from "react-router"

interface PostCardProps extends React.HTMLProps<any> {
  post: PostFragment
}
const PostCard = ({ post, className }: PostCardProps) => {
  const navigate = useNavigate()

  return (
    <Card
      rootClassName="cursor-pointer"
      title={post.profile.handle ?? "<Handle not found>"}
      className={className}
      onClick={() => navigate(`/post/${post.id}`)}
    >
      <Meta title={post.metadata.name} description={post.createdAt} />
      <p className="mt-4">{post.metadata.content}</p>
    </Card>
  )
}
export default PostCard
