import React from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import HomePage from "./pages/HomePage"
import StateProvider from "./state"
import ProfilePage from "./pages/ProfilePage"
import CreatePostPage from "./pages/CreatePostPage"
import ViewPostPage from "./pages/ViewPostPage"
import SearchPage from "./pages/SearchPage"

// random color I found which I like: #E77F6E

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/profile/:id",
    element: <ProfilePage />,
  },
  {
    path: "/post/create",
    element: <CreatePostPage />,
  },
  {
    path: "/post/:postId",
    element: <ViewPostPage />,
  },
  {
    path: "/search",
    element: <SearchPage />,
  },
])

function App() {
  return (
    <StateProvider>
      <RouterProvider router={router} />
    </StateProvider>
  )
}
export default App
