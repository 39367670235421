import Title from "antd/es/typography/Title"
import React from "react"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"
import remarkGfm from "remark-gfm"

const MarkdownView = ({ children }: { children: string }) => {
  return (
    <ReactMarkdown
      children={children}
      remarkPlugins={[remarkGfm]}
      components={{
        h1: ({ children }) => <Title level={1}>{children}</Title>,
        h2: ({ children }) => <Title level={2}>{children}</Title>,
        h3: ({ children }) => <Title level={3}>{children}</Title>,
        h4: ({ children }) => <Title level={4}>{children}</Title>,
        h5: ({ children }) => <Title level={5}>{children}</Title>,
        pre: ({ children }) => <pre className="bg-gray-200 p-2 rounded">{children}</pre>,
      }}
    />
  )
}
export default MarkdownView
