import {
  PostFragment,
  PublicationMetadataV2Input,
  PublicationsQueryRequest,
  isRelayerError,
} from "@lens-protocol/client"
import { lensClient, upload } from "."
import { ethers } from "ethers"

export async function createPost(
  profileId: string,
  metadata: PublicationMetadataV2Input,
  options?: { wait: boolean }
): Promise<string> {
  const contentURI = await upload(metadata)

  const postTypedData = await lensClient.publication.createPostTypedData({
    profileId,
    contentURI,
    collectModule: {
      revertCollectModule: true, // collect disabled
    },
    referenceModule: {
      followerOnlyReferenceModule: false, // anybody can comment or mirror
    },
  })

  const provider = new ethers.providers.Web3Provider((window as any).ethereum)
  const signer = provider.getSigner()

  const { typedData, id } = postTypedData.unwrap()
  const signature = await signer._signTypedData(typedData.domain, typedData.types, typedData.value)

  const broadcastResult = await lensClient.transaction.broadcast({
    id,
    signature,
  })

  const unwrappedBroadcastResult = broadcastResult.unwrap()
  if (isRelayerError(unwrappedBroadcastResult)) {
    throw new Error(unwrappedBroadcastResult.reason)
  }

  // wait for post to be indexed
  if (options?.wait) {
    await lensClient.transaction.waitForIsIndexed(unwrappedBroadcastResult.txId)
  }

  return unwrappedBroadcastResult.txHash
}

export async function createComment(
  profileId: string,
  publicationId: string,
  metadata: PublicationMetadataV2Input,
  options?: { wait: boolean }
): Promise<string> {
  const contentURI = await upload(metadata)

  const commentTypedData = await lensClient.publication.createCommentTypedData({
    profileId,
    publicationId,
    contentURI,
    collectModule: {
      revertCollectModule: true,
    },
    referenceModule: {
      followerOnlyReferenceModule: false,
    },
  })

  const provider = new ethers.providers.Web3Provider((window as any).ethereum)
  const signer = provider.getSigner()

  const { typedData, id } = commentTypedData.unwrap()
  const signature = await signer._signTypedData(typedData.domain, typedData.types, typedData.value)

  const broadcastResult = await lensClient.transaction.broadcast({
    id,
    signature,
  })

  const unwrappedBroadcastResult = broadcastResult.unwrap()
  if (isRelayerError(unwrappedBroadcastResult)) {
    throw new Error(unwrappedBroadcastResult.reason)
  }

  // wait for post to be indexed
  if (options?.wait) {
    await lensClient.transaction.waitForIsIndexed(unwrappedBroadcastResult.txId)
  }

  return unwrappedBroadcastResult.txHash
}

export async function fetchPostByTxHash(txHash: string): Promise<PostFragment> {
  const result = await lensClient.publication.fetch({ txHash })
  if (!result) {
    throw new Error(`Post with hash '${txHash}' not found.`)
  }

  return result as PostFragment
}

export async function fetchPostById(publicationId: string): Promise<PostFragment> {
  const result = await lensClient.publication.fetch({ publicationId })
  if (!result) {
    throw new Error(`Post with Publication ID '${publicationId}' not found.`)
  }

  return result as PostFragment
}

export async function fetchPosts(criteria: PublicationsQueryRequest): Promise<PostFragment[]> {
  const result = await lensClient.publication.fetchAll(criteria)
  return result.items as PostFragment[]
}
