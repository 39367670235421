import { useParams } from "react-router"
import Header from "../components/Header"
import { Button, Card, Empty, Image, Spin, Timeline, Typography } from "antd"
import { ipfsToHttps } from "../utils/ipfsToHttps"
import usePost from "../state/post/usePost"
import { useCallback, useEffect, useState } from "react"
import TextArea from "antd/es/input/TextArea"
import TimelineItem from "antd/es/timeline/TimelineItem"
import MarkdownView from "../components/MarkdownView"

const ViewPostPage = () => {
  const { postId } = useParams()
  const { post, postLoading, comments, commentsLoading, fetchComments, addComment, addingComment } =
    usePost(postId!)
  const [commentValue, setCommentValue] = useState("")

  useEffect(() => {
    fetchComments()
  }, [])

  const handlePostComment = useCallback(async () => {
    await addComment(commentValue)
    setCommentValue("")
  }, [commentValue])

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <Header createPost />
      {postLoading && <Spin />}
      {!postLoading && !post && <Empty />}
      {!postLoading && post && (
        <div className="flex-1 flex flex-col items-center overflow-auto">
          <div className="flex-1 flex flex-col max-w-5xl px-16 py-8 w-full">
            <Card className="flex flex-col mb-8">
              <div className="flex flex-row items-center">
                <Image
                  rootClassName="rounded-lg overflow-hidden"
                  src={ipfsToHttps((post.profile.picture as any).original.url)}
                  height={64}
                  preview={false}
                />
                <div className="flex flex-col ml-4">
                  <Typography.Text className="text-gray-400">
                    Posted on {post.createdAt} by{" "}
                    <Typography.Link href={`/profile/${post.profile.id}`}>
                      {post.profile.handle}
                    </Typography.Link>
                  </Typography.Text>
                  <Typography.Text className="text-2xl font-bold">
                    {post.metadata.name}
                  </Typography.Text>
                </div>
              </div>

              <div className="flex-1 flex flex-col p-4 mt-4">
                <MarkdownView children={post.metadata.content ?? ""} />
              </div>
            </Card>

            {/* Comments */}
            {commentsLoading && comments.length === 0 && <Spin />}
            {!commentsLoading && comments.length > 0 && (
              <Timeline className="px-8">
                {comments.map((c) => (
                  <TimelineItem className="flex flex-col" color="#E77F6E">
                    <div className="flex justify-between">
                      <Typography.Text className="text-sm text-gray-400">
                        {c.profile.handle}
                      </Typography.Text>
                      <Typography.Text className="text-sm text-gray-400">
                        {c.createdAt}
                      </Typography.Text>
                    </div>
                    <p>{c.metadata.content}</p>
                  </TimelineItem>
                ))}
              </Timeline>
            )}

            {/* Comment box */}
            <TextArea
              className="mb-4"
              disabled={addingComment || commentsLoading}
              value={commentValue}
              onChange={(e) => setCommentValue(e.target.value)}
              placeholder="Add comment"
              autoSize={{ minRows: 3 }}
            />
            <Button
              className="self-start"
              disabled={!commentValue || addingComment || commentsLoading}
              loading={addingComment}
              onClick={handlePostComment}
            >
              Post
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
export default ViewPostPage
