import { Button, Input, Typography } from "antd"
import Header from "../components/Header"
import Title from "antd/es/typography/Title"
import TextArea from "antd/es/input/TextArea"
import { useEffect, useState } from "react"
import useCreatePost from "../state/post/useCreatePost"
import { useNavigate } from "react-router"
import MDEditor from "@uiw/react-md-editor"
import rehypeSanitize from "rehype-sanitize"

const CreatePostPage = () => {
  const { createPost, loading, error, post } = useCreatePost()
  const [title, setTitle] = useState("")
  const [content, setContent] = useState("")
  const navigate = useNavigate()

  useEffect(() => {
    if (post) {
      navigate(`/post/${post.id}`)
    }
  }, [post])

  return (
    <div className="flex-1 flex flex-col">
      <Header />
      <div className="flex-1 flex m-4 justify-center">
        <div className="flex-1 flex flex-col max-w-3xl">
          <Title level={3}>Create a new post</Title>
          <label htmlFor="title">Title</label>
          <Input
            disabled={loading}
            name="title"
            title="Title"
            className="mb-2"
            onChange={(e) => setTitle(e.target.value)}
          />
          <label htmlFor="content">Markdown</label>
          <MDEditor
            className="mb-4"
            data-color-mode="light"
            value={content}
            onChange={(v) => setContent(v ?? "")}
            previewOptions={{ rehypePlugins: [[rehypeSanitize]] }}
          />
          <Button
            loading={loading}
            disabled={loading}
            className="self-start w-24"
            onClick={() => createPost(title, content)}
          >
            Post
          </Button>
          {error && <Typography className="text-red-500">{error}</Typography>}
        </div>
      </div>
    </div>
  )
}
export default CreatePostPage
