import { useCallback, useState } from "react"
import { useAuthState } from "."
import { login } from "../../api/auth"
import { ProfileFragment } from "@lens-protocol/client"

const useProfile = (
  address?: string
): {
  profile?: ProfileFragment
  loading: boolean
  error?: string

  login: () => Promise<void>
  logout: () => void
} => {
  const [state, dispatch] = useAuthState()
  const [error, setError] = useState<string | undefined>()
  const [loading, setLoading] = useState(false)

  return {
    profile: state.profile,
    loading,
    error,

    login: useCallback(async () => {
      setLoading(true)
      setError(undefined)

      try {
        const addr = address ?? state.ethAddress
        if (!addr) {
          throw new Error("No wallet connected")
        }

        const profile = await login(addr)
        if (!profile) {
          throw new Error("Profile not found")
        }

        dispatch({ type: "SET_PROFILE", payload: { profile } })
        setLoading(false)
      } catch (err: any) {
        console.error({ err })
        setError(err.message)
        setLoading(false)
      }
    }, [state.ethAddress, address]),

    logout: useCallback(async () => {
      setError(undefined)
      dispatch({ type: "LOGOUT" })
    }, []),
  }
}
export default useProfile
