import { useEffect, useState } from "react"
import { useLocation } from "react-router"

export default function useQueryParams() {
  const [params, setParams] = useState<any>({})
  const location = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    let params: any = {}

    queryParams.forEach((value, key) => {
      params[key] = value
    })

    setParams(params)
  }, [location.search])

  return params
}
