import {
  Dispatch,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react"
import postReducer, { PostAction, PostState } from "./reducer"

const storedPostState = localStorage.getItem("post_state")
const initialPostState: PostState = !!storedPostState
  ? JSON.parse(storedPostState)
  : {
      posts: {},
    }

export const PostContext = createContext<PostState>(initialPostState)
export const PostDispatchContext = createContext<Dispatch<PostAction>>(() => {})

export const usePostState = (): [PostState, Dispatch<PostAction>] => {
  const state = useContext(PostContext)
  const dispatch = useContext(PostDispatchContext)

  return [state, dispatch]
}

const PostProvider = ({ children }: PropsWithChildren) => {
  const [postState, postDispatch] = useReducer(postReducer, initialPostState)

  useEffect(() => {
    localStorage.setItem("post_state", JSON.stringify(postState))
  }, [postState])

  return (
    <PostContext.Provider value={postState}>
      <PostDispatchContext.Provider value={postDispatch}>{children}</PostDispatchContext.Provider>
    </PostContext.Provider>
  )
}
export default PostProvider
