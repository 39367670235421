import React from "react"
import { Card, Descriptions, Image } from "antd"
import { ProfileFragment } from "@lens-protocol/client"
import { ipfsToHttps } from "../utils/ipfsToHttps"

const ProfileCard = ({ profile }: { profile: ProfileFragment }) => {
  return (
    <Card key={profile.id} className="mb-4">
      <div className="flex flex-row">
        <Image
          rootClassName="shrink-0"
          fallback="/fallback.png"
          src={ipfsToHttps((profile.picture as any)?.original?.url)}
          height={256}
        />
        <Descriptions title={profile.handle} column={1} className="ml-8">
          <Descriptions.Item label="User ID">{profile.id}</Descriptions.Item>
          <Descriptions.Item label="Owner Address">{profile.ownedBy}</Descriptions.Item>
          <Descriptions.Item label="Interests">
            {(profile.interests?.length ?? 0) > 0 ? profile.interests?.join(",") : "None"}
          </Descriptions.Item>
          <Descriptions.Item label="Bio">{profile.bio ?? "N/A"}</Descriptions.Item>
        </Descriptions>
      </div>
    </Card>
  )
}
export default ProfileCard
