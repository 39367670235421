import { lensClient } from "."
import { ethers } from "ethers"
import { ProfileFragment } from "@lens-protocol/client"

export async function login(address: string): Promise<ProfileFragment | undefined> {
  const challenge = await lensClient.authentication.generateChallenge(address)

  const provider = new ethers.providers.Web3Provider((window as any).ethereum)
  const signer = provider.getSigner()

  const signature = await signer.signMessage(challenge)

  await lensClient.authentication.authenticate(address, signature)

  const isAuthenticated = await lensClient.authentication.isAuthenticated()
  if (!isAuthenticated) {
    return undefined
  }

  const fetchAllResult = await lensClient.profile.fetchAll({
    ownedBy: [address],
    limit: 1,
  })

  return fetchAllResult.items[0]
}
