import { PostFragment, PublicationTypes, PublicationsQueryRequest } from "@lens-protocol/client"
import { useCallback, useState } from "react"
import { fetchPosts } from "../../api/post"
import useProfile from "../auth/useProfile"
import { usePostState } from "."

const usePosts = (
  criteria: PublicationsQueryRequest
): {
  posts: { [postId: string]: PostFragment }
  loading: boolean
  error?: string

  refresh: () => Promise<void>
} => {
  const { profile } = useProfile()
  const [state, dispatch] = usePostState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>()

  const refresh = useCallback(async () => {
    try {
      setLoading(true)
      setError(undefined)

      if (!profile?.id) {
        setError("Not signed in")
        return
      }

      const options = {
        ...criteria,
      }

      // TODO: this list should be based on a following
      if (!criteria.publicationIds) {
        options.profileIds = [profile.id]
        options.publicationTypes = [PublicationTypes.Post]
      }

      const posts = await fetchPosts(options)
      dispatch({ type: "ADD_POSTS", payload: { posts } })
      setLoading(false)
    } catch (err: unknown) {
      setError(`${err}`)
      setLoading(false)
    }
  }, [profile?.id])

  return {
    posts: state.posts,
    loading,
    error,

    refresh,
  }
}
export default usePosts
