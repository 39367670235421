import { PostFragment, PublicationMainFocus } from "@lens-protocol/client"
import { v4 as uuid } from "uuid"
import usePosts from "./usePosts"
import { useCallback, useState } from "react"
import { createComment, fetchPosts } from "../../api/post"
import useProfile from "../auth/useProfile"

const usePost = (
  postId: string
): {
  post?: PostFragment
  postLoading: boolean
  error?: string
  comments: PostFragment[]
  commentsLoading: boolean
  commentsError?: string

  refresh: () => Promise<void>
  fetchComments: () => Promise<void>

  addComment: (text: string) => Promise<void>
  addingComment: boolean
} => {
  const { profile } = useProfile()
  const posts = usePosts({
    publicationIds: [postId!],
    limit: 1,
  })
  const post = posts.posts[postId]

  const [comments, setComments] = useState<PostFragment[]>([])
  const [commentsLoading, setCommentsLoading] = useState(false)
  const [commentsError, setCommentsError] = useState<string | undefined>()
  const [addingComment, setAddingComment] = useState(false)

  const fetchComments = useCallback(async () => {
    setCommentsError(undefined)
    setCommentsLoading(true)
    try {
      const comments = await fetchPosts({
        commentsOf: postId,
      })
      setComments(comments.reverse())
    } catch (err: unknown) {
      setCommentsError(`${err}`)
    }
    setCommentsLoading(false)
  }, [postId])

  const addComment = useCallback(
    async (text: string) => {
      if (!text || !profile) {
        return
      }
      setAddingComment(true)

      try {
        await createComment(
          profile.id,
          postId,
          {
            name: `Comment on "${post.metadata.content}"`,
            content: text,
            appId: "fifty-one",
            version: "2.0.0",
            metadata_id: uuid(),
            mainContentFocus: PublicationMainFocus.TextOnly,
            attributes: [],
            locale: "en-US",
          },
          { wait: true }
        )

        fetchComments()
      } catch (err: unknown) {
        console.error({ err })
      }
      setAddingComment(false)
    },
    [fetchComments, profile, postId, posts]
  )

  return {
    post,
    postLoading: posts.loading,
    error: posts.error,
    comments,
    commentsLoading,
    commentsError,

    refresh: posts.refresh,
    fetchComments,
    addComment,
    addingComment,
  }
}
export default usePost
