import { useCallback, useState } from "react"
import useProfile from "../auth/useProfile"
import { createPost, fetchPostByTxHash } from "../../api/post"
import { PostFragment, PublicationMainFocus } from "@lens-protocol/client"
import { v4 as uuid } from "uuid"
import { usePostState } from "."

const useCreatePost = (): {
  post?: PostFragment
  loading: boolean
  error?: string

  createPost: (title: string, content: string) => void
} => {
  const { profile } = useProfile()
  const [postState, dispatch] = usePostState()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const [postId, setPostId] = useState("")

  return {
    post: postState.posts[postId],
    loading,
    error,

    createPost: useCallback(
      async (name: string, content: string) => {
        setLoading(true)
        setError(undefined)

        if (!profile) {
          setError("Not logged in")
          setLoading(false)
          return
        }

        try {
          const txHash = await createPost(
            profile.id,
            {
              name,
              content,
              appId: "fifty-one",
              version: "2.0.0",
              metadata_id: uuid(),
              mainContentFocus: PublicationMainFocus.TextOnly,
              attributes: [],
              locale: "en-US",
            },
            { wait: true }
          )
          const post = await fetchPostByTxHash(txHash)

          setPostId(post.id)
          dispatch({
            type: "ADD_POSTS",
            payload: {
              posts: [post],
            },
          })
          setLoading(false)
        } catch (err: any) {
          setError(`${err}`)
          setLoading(false)
        }
      },
      [profile]
    ),
  }
}
export default useCreatePost
