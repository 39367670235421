import { PostFragment } from "@lens-protocol/client"

export interface PostState {
  posts: { [id: string]: PostFragment }
}

export interface AddPostsAction {
  type: "ADD_POSTS"
  payload: {
    posts: PostFragment[]
  }
}

export type PostAction = AddPostsAction

export default (state: PostState, action: PostAction) => {
  switch (action.type) {
    case "ADD_POSTS":
      return {
        ...state,
        posts: {
          ...state.posts,
          // convert the list of posts to an object keyed by ID
          ...action.payload.posts.reduce((p, post) => ({ ...p, [post.id]: post }), {}),
        },
      }
    default: {
      return state
    }
  }
}
